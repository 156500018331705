import axios from 'axios';
import Reader from 'containers/Reader';
import CryptoJS from 'crypto-js';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
const BookReader = () => {

    const viewerRef = useRef(null);
    const  params  = useParams();
    let bodyFormData = new FormData();
    const [url, setUrl] = useState('');
    const [ip, setIP] = useState('');
    const [userId, setUserid] = useState('')

    const [access , setAccess] = useState(null)

    const getData = async () => {
        const res = await axios.get('https://api.boighor.com/api/getappsettings?fromsrc=web');
        setIP(res.data.data.ip_addr);
    };

    useEffect(()=>{
    getData();
    const   readerTime = Date.now()
    const encript = params['*'];
    const  decrypt = CryptoJS.AES.decrypt(encript, `eb$bdre@derb0!gh0r##`);
    const decryptStr = decrypt.toString(CryptoJS.enc.Utf8)
    const decryptInfo = decryptStr.slice(1,-1).split('////')  // slice use for removeing quotation("") from first and last string.
    const [bookcode, reqTime, reqIP, msisdn] = decryptInfo;

  //  console.log(decryptInfo.length);
  //  console.log(readerTime-parseInt(reqTime));
  //  console.log(readerTime>parseInt(reqTime), 'and', readerTime<parseInt(reqTime)+25000, 'and', reqIP ===ip);
    if(ip){
      if(reqIP !==ip){
        setAccess(false)
        return;
      }
      // console.log('Req IP',reqIP,'deviceIp', ip);
      if (!(readerTime>parseInt(reqTime) && readerTime<parseInt(reqTime)+40000)   || reqIP !==ip ){
        // console.log('redirect');
        
        // console.log(readerTime>parseInt(reqTime), 'and', readerTime<parseInt(reqTime)+25000, 'and', reqIP ===ip);
         window.open(`${process.env.REACT_APP_READER_PERMISSION_URL}/${encript}`, '_self');
        return;
      }else if(decryptInfo.length === 4){
        setAccess(true)
        setUserid(msisdn)
        bodyFormData.append('bookcode',bookcode);
        bodyFormData.append('msisdn', msisdn);
        bodyFormData.append('fromsrc', 'web');
          axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL,
            data: bodyFormData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(function (response) {
                // send a token from client to server side, server return samet token. if match then store data, otherwise not
                if(response.data.status.responseCode === '1'){
                  setUrl(response.data.data.url);
                 
                }else{
                  setUrl('')
                }
               
            })
            .catch(function (error) {
                // console.log(error);
            });
     
      }else {
      setUrl('')
     }
    }
  
      },[ip]);
    return (
        <div>
          {
          url !== ''
            ? <Reader url={url}  userId={userId}  ref={viewerRef} /> :  
            <div style={{height: "100vh", display:'flex',justifyContent:'center', alignItems:'center'}}>
              {
                access === false ?<h1>Permissions not granted</h1> : <h1 >Please wait</h1>
              }
                  
            </div>     
          }

       
          
        </div>
    );
};

export default BookReader;



    // const [bl, setBl] = useState({})
    // useEffect(()=>{
    //   // #http://localhost:5000/file
    //     fetch('https://gerhardsletten.github.io/react-reader/files/alice.epub')
    //     .then(res=>res.blob())
    //     .then(data=>{
    //       console.log(data);
    //       // const href = URL.createObjectURL(data);
    //       // console.log(URL.createObjectURL(data));
    
    
    //       // // URL.revokeObjectURL(href);
    //       setBl(data)
    //     })
    //     },[])
    //     const myFile = new File([bl], 'oo.epub', {
    //       type: bl.type,
    //   });